import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import { MsalAuthenticationTemplate, useMsal, useAccount } from "@azure/msal-react"
import { InteractionStatus, InteractionType, InteractionRequiredAuthError } from "@azure/msal-browser"
import { apiRequest } from "../authConfig"

import Layout from "../components/layout"
import Seo from "../components/seo"
import { JsonData } from "../components/jsonData"
import { Loading } from "../components/loading"
import { ErrorComponent } from "../components/errorComponent"
import { invokeSearch } from "../utils/customApiCall"

import TextField from '@mui/material/TextField'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { CardActionArea } from '@mui/material'

import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment'
import IconButton from '@mui/material/IconButton'
import SearchIcon from '@mui/icons-material/Search'
import BugReportIcon from '@mui/icons-material/BugReport'
import MenuBookIcon from '@mui/icons-material/MenuBook'

const SearchResults = (props) => {
  console.log(props.searchResults);

  var searchResults = JSON.parse(props.searchResults);

  const resultsList = []
  for (let index = 0; index < searchResults.results.length; index++) {
    const result = searchResults.results[index];
    
    resultsList.push(<Link to={`/release/${result.document.Iteration}`} style={{ textDecoration: "none", color: "inherit" }} key={index}>
    <li>
        <Card sx={{ minWidth: 275 }}>
          <CardActionArea>
            <CardContent>
              <Typography variant="h4" component="h3" gutterBottom>
                {result.document.Title}
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                <MenuBookIcon fontSize="large" style={{ color: "#006dff", verticalAlign: "middle", marginRight: "15px" }} /> {result.document.Body.Enhancements.length} Enhancements
              </Typography>
              <Typography sx={{ mb: 1.5 }} color="text.secondary">
                <BugReportIcon fontSize="large" style={{ color: "red", verticalAlign: "middle", marginRight: "15px" }} /> {result.document.Body.Bugs.length} Bugs
              </Typography>
            </CardContent>
          </CardActionArea>
          {/* <CardActions>
            <Button size="small">Learn More</Button>
          </CardActions> */}
        </Card>
      </li></Link>)
  }

  return (
      <>
        <span>Count: {searchResults.count}</span>
        <br />
        <br />
        <ul style={{ listStyle: "none", marginLeft: "inherit" }}>
          {resultsList}
        </ul>
      </>
  );
};


const SearchContent = () => {
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [graphData, setGraphData] = useState(null);

  function getSearchResults(accessToken, searchText) {
    var searchRequest = {
      q: searchText,
      skip: 0,
      top: 10,
      filters: []
    }

    invokeSearch(accessToken, JSON.stringify(searchRequest)).then(searchResponse => 
      searchResponse.text().then(function(data) {
        setGraphData(data)
        window.localStorage.setItem("lastSearchResults", data)
        window.localStorage.setItem("lastSearch", searchText);
      })
    );
  }

  function trySearch() {
    if (account && inProgress === InteractionStatus.None) {
      const request = {
          ...apiRequest,
          account: account
      };

      if (!graphData) {
        window.localStorage.removeItem("lastSearch");

        var previousSearchResults = window.localStorage.getItem("lastSearchResults");

        if (previousSearchResults) {
          setGraphData(previousSearchResults)
        }
      }

      instance.acquireTokenSilent(request).then((response) => {
        window.localStorage.setItem("accessToken", JSON.stringify(response.accessToken));
        window.localStorage.setItem("authClaims", JSON.stringify(response.account.idTokenClaims));

        var searchText = window.document.getElementById("searchInput").value;

        // Start debounce timer
        setTimeout(() => {
          var updatedText = window.document.getElementById("searchInput").value;

          var previousSearch = window.localStorage.getItem("lastSearch");

          if (searchText === updatedText && previousSearch !== updatedText && updatedText.length > 0) {
            getSearchResults(response.accessToken, updatedText);
          }
        }, 1000)
      }).catch((e) => {
        if (e instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(request);
        }
      });
    }
  }

  useEffect(() => {
    trySearch();
  }, [account, inProgress, instance, graphData]);

  return (
      <>
        <TextField
          fullWidth
          id="searchInput"
          label="Search"
          variant="filled"
          defaultValue={global?.window?.localStorage?.getItem("lastSearch")}
          onChange={trySearch}
          InputProps={{
            endAdornment: <InputAdornment position="end">
              <IconButton
                aria-label="search icon"
                onClick={trySearch}
                // onMouseDown={handleMouseDownPassword}
                edge="end"
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>,
          }}
        />
        <br />
        <br />
        { graphData ? <SearchResults searchResults={graphData} /> : null }
      </>
  );
};

const SearchPage = () => {
  const authRequest = {
      ...apiRequest
  };

  return (
      <Layout>
        <Container maxWidth="xl">
          <Box sx={{ my: 4 }}>
            <Seo title="Search" />
            <Typography variant="h4" component="h1" gutterBottom>
                Search
            </Typography>
            <MsalAuthenticationTemplate 
                interactionType={InteractionType.Redirect} 
                authenticationRequest={authRequest} 
                errorComponent={ErrorComponent} 
                loadingComponent={Loading}
            >
                <SearchContent />
            </MsalAuthenticationTemplate>
            <Link to="/">Go back to the homepage</Link>
            </Box>
        </Container>
      </Layout>
    )
};

export default SearchPage
