import * as React from "react"

/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const JsonData = (props) => {
    console.log(props.graphData);

    return (
        <div id="profile-div">
            <p><strong>Data: </strong> {JSON.stringify(props.graphData)}</p>
        </div>
    );
};
